import React from "react";
import {Route, Switch} from "react-router-dom";

import Users from "./Users";
import Settings from "./Settings";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}users`} component={Users}/>
      <Route path={`${match.url}ajustes`} component={Settings}/>
      <Route path={`${match.url}account`} component={asyncComponent(() => import('../pages/account'))}/>
      <Route path={`${match.url}homologation`} component={asyncComponent(() => import('../pages/homologation'))}/>
    </Switch>
  </div>
);

export default App;
