import {
    PROFILE_PICTURE_UPLOAD_START,
    PROFILE_PICTURE_UPLOAD_SUCCESS,
    PROFILE_PICTURE_UPLOAD_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {
    loading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROFILE_PICTURE_UPLOAD_START: {
            return {
                ...state,
                loading: true
            }
        }
        case PROFILE_PICTURE_UPLOAD_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case PROFILE_PICTURE_UPLOAD_FAILED: {
            return {
                ...state,
                loading: false
            }
        }

        default:
            return state;
    }
}