import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import { NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;

const SidebarContent = () => {

  let { navStyle } = useSelector(({settings}) => settings);
  let { pathname } = useSelector(({common}) => common);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  return (
    <>
      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div className="gx-sidebar-notifications">
          <UserProfile/>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme="dark"
            mode="inline">
              <Menu.Item key="users">
                <Link to="/users">
                  <i className="icon icon-user user-group-left"/>
                  <i className="icon icon-user user-group-right"/>
                  <span>Usuários</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="homologation">
                <Link to="/homologation">
                  <i className="icon icon-select"/>
                  <span>Homologação</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="manual">
                  <a target="_blank" href="https://montebravocorp.sharepoint.com/Documentos%20Compartilhados/Forms/Lista.aspx?FolderCTID=0x012000CEA6DAA206C1974F98F883EE63EB8260&id=%2FDocumentos%20Compartilhados%2FHub%20de%20assessoria%2FFAQ%20Comiss%C3%B5es%2FFAQ%20COMISS%C3%95ES%2Epdf&parent=%2FDocumentos%20Compartilhados%2FHub%20de%20assessoria%2FFAQ%20Comiss%C3%B5es">
                    <i className="icon icon-inbox"/>
                    <span>Manual</span>
                  </a>
                </Menu.Item>
              <SubMenu key="ajustes" popupClassName={getNavStyleSubMenuClass(navStyle)}
                       title={<span> <i className="icon icon-setting"/>
                         <span>Ajustes</span></span>}>
                <Menu.Item key="ajustes/regras-comissao">
                  <Link to="/ajustes/regras-comissao">
                    <i className="icon icon-table-data"/>
                    <span>Regras comissão</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ajustes/comissao-floor">
                  <Link to="/ajustes/comissao-floor">
                    <i className="icon icon-table-data"/>
                    <span>Comissao Floor</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ajustes/lider-equipe-n2">
                  <Link to="/ajustes/lider-equipe-n2">
                    <i className="icon icon-table-data"/>
                    <span>Lider Equipe N2</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ajustes/diversos">
                  <Link to="/ajustes/diversos">
                    <i className="icon icon-table-data"/>
                    <span>Ajustes</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ajustes/descontos">
                  <Link to="/ajustes/descontos">
                    <i className="icon icon-table-data"/>
                    <span>Descontos</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ajustes/processamento-comissao">
                  <Link to="/ajustes/processamento-comissao">
                    <i className="icon icon-table-data"/>
                    <span>Processamento Comissão</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/ajustes/investor">
                  <Link to="/ajustes/investor">
                    <i className="icon icon-table-data"/>
                    <span>Investor</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/ajustes/exibicao-extrato">
                  <Link to="/ajustes/exibicao-extrato">
                    <i className="icon icon-table-data"/>
                    <span>Exibição extrato</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/ajustes/logs">
                  <Link to="/ajustes/logs">
                    <i className="icon icon-table-data"/>
                    <span>Logs</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

