import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import http, {rebuildHttp} from 'util/Api'
import axios from 'axios';

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch({type: SIGNOUT_USER_SUCCESS});
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    http.get('auth/me',
    ).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error.message});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

/**
 * admin sign in
 * 
 * @param {String} email
 * @param {String} password
 */
export const adminSignIn = ({email, password}) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`${process.env.REACT_APP_API_URL}/superadmin/auth/login`, {
      email: email,
      password: password,
    }).then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        localStorage.setItem("user", JSON.stringify(data.user.id));
        rebuildHttp();
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error.message});
      }
    }).catch((error) => {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error);
    })
  }
}
