import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { onNavStyleChange } from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";


const SidebarLogo = () => {
  const dispatch = useDispatch();
  const { width } = useSelector(({settings}) => settings);
  let navStyle = useSelector(({settings}) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">

      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR)
        ? <div className="gx-linebar">
            <i
              className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'}`}
              onClick={() => {
                if (navStyle === NAV_STYLE_FIXED) {
                  dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR))
                } else {
                  dispatch(onNavStyleChange(NAV_STYLE_FIXED))
                }
              }}
            />
          </div>
        : null}

      <Link to="/" className="gx-site-logo">
        {width >= TAB_SIZE
          ? <img alt="" src={require("assets/images/w-logo.png")}/>
          : <img alt="" src={require("assets/images/logo.png")}/>}
      </Link>

    </div>
  );
};

export default SidebarLogo;
