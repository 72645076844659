import axios from 'axios';

const http =  axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
});

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href='/';
    }
    return Promise.reject(error);
  }
)

export const rebuildHttp = () => {
  let headers = {}
  let token = JSON.parse(window.localStorage.getItem("token"));

  if (token) {
    headers = {...headers, Authorization: `Bearer ${token}`}
  }
  http.defaults.headers.common = headers
}

rebuildHttp();

export default http