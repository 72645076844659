import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const Settings = ({match}) => (
  <Switch>
    <Route path={`${match.url}/regras-comissao`} component={asyncComponent(() => import('../../pages/settings/regras_comissao'))}/>
    <Route path={`${match.url}/comissao-floor`} component={asyncComponent(() => import('../../pages/settings/comissao_floor'))}/>
    <Route path={`${match.url}/lider-equipe-n2`} component={asyncComponent(() => import('../../pages/settings/lider_equipe_n2'))}/>
    <Route path={`${match.url}/diversos`} component={asyncComponent(() => import('../../pages/settings/diversos'))}/>
    <Route path={`${match.url}/processamento-comissao`} component={asyncComponent(() => import('../../pages/settings/processamento_comissao'))}/>
    <Route path={`${match.url}/exibicao-extrato`} component={asyncComponent(() => import('../../pages/settings/exibicao_extrato'))}/>
    <Route path={`${match.url}/investor`} component={asyncComponent(() => import('../../pages/settings/investor'))}/>
    <Route path={`${match.url}/logs`} component={asyncComponent(() => import('../../pages/settings/logs'))}/>
    <Route path={`${match.url}/descontos`} component={asyncComponent(() => import('../../pages/settings/desconto'))}/>


  </Switch>
);

export default Settings;
