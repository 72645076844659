import { TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from "constants/ActionTypes";
import { NAV_STYLE } from "../../constants/ThemeSetting";


export function toggleCollapsedSideNav(navCollapsed) {
  return {type: TOGGLE_COLLAPSED_NAV, navCollapsed};
}

export function updateWindowWidth(width) {
  return (dispatch) => {
    dispatch({type: WINDOW_WIDTH, width});
  }

}

export function onNavStyleChange(navStyle) {
  return (dispatch) => {
    dispatch({type: NAV_STYLE, navStyle});
  }
}
