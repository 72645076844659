import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const Users = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/>
    <Route path={`${match.url}/list`} component={asyncComponent(() => import('../../pages/users/list'))}/>
    <Route path={`${match.url}/register`} component={asyncComponent(() => import('../../pages/users/register'))}/>
  </Switch>
);

export default Users;
