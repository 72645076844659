import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Checkbox, Form,  Input} from "antd";
import {Link} from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";

import {adminSignIn} from "../appRedux/actions/Auth";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    console.log("finish",values)
    dispatch(adminSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);
  
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header gx-text-center">
          <img src={require("assets/images/logo-montebravo-preto.png")} width="200" />
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">
          <FormItem rules={[{ required: true, message: 'Por favor insira seu E-mail!' }]} name="email">
              <Input prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}} className="text-gray" />}
                     placeholder="Email"/>
          </FormItem>
          <FormItem rules= {[{required: true, message: 'Por favor insira sua senha!'}]}  name="password">
              <Input prefix={<LockOutlined  style={{color: 'rgba(0,0,0,.25)'}} className="text-gray" />}
                     type="password"
                     placeholder="Senha"/>
          </FormItem>
          <FormItem  name="remember" valuePropName="checked">
              <Checkbox>Lembre de mim</Checkbox>
            <Link className="gx-login-form-forgot" to="/custom-views/user-auth/forgot-password">Esqueceu a senha</Link>
          </FormItem>
          <FormItem className="gx-text-center">
            <Button type="primary" htmlType="submit">
              Entrar
            </Button>
          </FormItem>
        </Form>
        <InfoView></InfoView>
      </div>
    </div>
  );
};

export default SignIn;
