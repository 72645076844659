import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import { ConfigProvider } from 'antd';
// import {IntlProvider} from "react-intl";

import MainApp from "./MainApp";
import SignIn from "../SignIn";
import { setInitUrl } from "appRedux/actions/Auth";
import locale from 'antd/lib/locale/pt_BR';


const RestrictedRoute = ({component: Component, location, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/login',
            state: {from: location}
          }}
        />}
  />;


const App = () => {
  const dispatch = useDispatch();
  // const { locale } = useSelector(({settings}) => settings);
  const { token, initURL } = useSelector(({auth}) => auth);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  }, [initURL, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/') {
      if (token === null) {
        history.push('/login');
      } else if (initURL === '' || initURL === '/' || initURL === '/login') {
        history.push('/users');
      } else {
        history.push(initURL);
      }
    }
    
  }, [token, initURL, location, history]);

  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <Route exact path='/login' component={SignIn}/>
        <RestrictedRoute path={`${match.url}`} token={token} location={location}
                          component={MainApp}/>
      </Switch>
    </ConfigProvider>
  )
};

export default memo(App);
