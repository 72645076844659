import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Avatar, Popover} from "antd";
import {userSignOut, getUser} from "appRedux/actions/Auth";

const UserInfo = () => {

  const dispatch = useDispatch();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/account" className="gx-text-gray">Minha conta</Link></li>
      <li onClick={() => dispatch(userSignOut())}>Sair</li>
    </ul>
  );

  const user = useSelector(({auth}) => auth.userInfo);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src={ user.cenario ? `${process.env.REACT_APP_FTP_URL}/` + user.cenario : "https://via.placeholder.com/150x150" }
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )

}

export default UserInfo;
